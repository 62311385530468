import React, { ChangeEvent, useContext, useState } from 'react'
import { Field } from '@cjdev-internal/visual-stack-x/Field'
import AppContext from '../../../context/AppContext'
import { AdminToolValues, FetchResponse } from '../../../types'
import messages from '../../../messages'
import { Stack } from '@cjdev-internal/visual-stack-x/Stack'
import { SingleFileUpload, useSingleFileUpload } from '@cjdev-internal/visual-stack-x/SingleFileUpload'
import { useIntl } from '@platform/cj-platform-navigation'


const FileUploader = () => {
  const intl = useIntl();
  const { commissionValues: { handleFileUpload, handleClearInputFile }, eventEmitter } = useContext<AdminToolValues>(AppContext)
  const config = useSingleFileUpload()

  React.useEffect(() => {
    console.log(config.file)
    config.file && handleFileUpload(config.file)
  }, [config.file]);

  const onClearClick = () => {
    config.onClear();
    handleClearInputFile();
  }

  //Register event
  React.useEffect(() => {
    eventEmitter.on('CLEAR_FILE_UPLOAD', onClearClick);
    return () => {
      eventEmitter.removeListener('CLEAR_FILE_UPLOAD', onClearClick);
    };
  }, []);

  return (
    <Stack gap="medium">
      <Field label={intl.formatMessage(messages.labelSearchCommissionFile)} />
      <SingleFileUpload {...config}
                        type="file"
                        onClear={onClearClick}
                        maxLength={1024}
      />
      <span>{intl.formatMessage(messages.labelCommissionFileUploadFileSize)} {config.file?.size ?? 0} {intl.formatMessage(messages.labelCommissionFileUploadBytes)}</span>
    </Stack>
  )
}

export default FileUploader


