import React from "react";
import { AdminToolValues, CommissionValues, FetchResponse, ToastOptions } from '../types'
import { User } from '@platform/cj-platform-navigation'
import Commission from '../components/Commission/Commission'
import EventEmitter from 'events'

const defaultCommissionValues: CommissionValues = {
  commissionFile: null,
  handleClearInputFile: () => null,
  handleFileUpload: (file: File | null) => null,
  handleSetToastOptions:  (data: FetchResponse) => null,
  commissionResponse: undefined,
  handleCommissionResponse: (commissionResponse: Commission[]) => null
}

const defaultValue: AdminToolValues = {
  isUnauthorized: false,
  handleAuthorization: () => null,
  commissionValues: defaultCommissionValues,
  eventEmitter: new EventEmitter()
}

const AppContext = React.createContext(defaultValue);

export default AppContext;