import Commission from '../Commission'
import React, { useState } from 'react'
import { CreateFileFn, Download, FetchDataFn, FetchProps } from '../../../types'
import { ActionMenuOptionDef, ColumnDef, DataGrid, useGridData } from '@cjdev-internal/visual-stack-x/DataGrid'
import { TopPanelProps } from '@cjdev-internal/visual-stack-x/components/DataGrid/TopPanel'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
import { ExpandingInputButton } from '@cjdev-internal/visual-stack-x/ExpandingInputButton'
import FetchBuilder from '../../../fetch/FetchBuilder'
import messages from '../../../messages'
import { useIntl } from '@platform/cj-platform-navigation'

const FileDisplayed = ({commissionData}: {commissionData: Commission[]}) => {
  const intl = useIntl();
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
  const [fetchDataProps, setFetchDataProps] = useState<FetchProps>({
    path: 'commission_file_result.txt',
    contentType: 'application/octet-stream'
  });

  const columnDefs: ColumnDef<Commission>[] = [
    { field: "key",
      type: "string",
      editable: false,
      filter: commissionData.filter((commission: Commission) => commission.key),
      headerName: intl.formatMessage(messages.labelTableColumnKey),
      suppressHeaderMenuButton: true,
      sortable: false,
    },
    { field: "lastModified",
      type: "string",
      editable: false,
      filter: commissionData.filter((commission: Commission) => commission.lastModified),
      sortable: true,
      sort: 'desc',
      headerName: intl.formatMessage(messages.labelTableColumnLastModified),
      suppressHeaderMenuButton: true
    },
    { field: "user",
      type: "string",
      editable: false,
      filter: commissionData.filter((commission: Commission) => commission.user),
      sortable: false,
      headerName: intl.formatMessage(messages.labelTableColumnUser),
      suppressHeaderMenuButton: true
    },
    { field: "email",
      type: "string",
      editable: false,
      filter: commissionData.filter((commission: Commission) => commission.email),
      sortable: false,
      headerName: intl.formatMessage(messages.labelTableColumnEmail),
      suppressHeaderMenuButton: true
    }
  ]

  const rowActions: ActionMenuOptionDef<Commission> = () => ([
    {
      label: "Download",
      icon: "download",
      onClick: (e) => handleDownload(e.rowData.key)
    }
  ])

  const topPanel = ({ api }: TopPanelProps<Commission>) => {
    return (
      <Row justify="space-between" align="center" gap="medium">
        <Row paddingLeft="medium" gap="large" align="center">
          <Text style={{ whiteSpace: "nowrap" }}>Quick Filter: </Text>
          <ExpandingInputButton onChange={(v) => api.setQuickFilter(v)} />
        </Row>
      </Row>
    )
  }

  const [rowData, cellEditRequest] = useGridData(commissionData, console.log);

  const handleDownload = (fileName: string) => {
    setFetchDataProps({ ...fetchDataProps, path: fileName });
    setIsFileSelected(true);
  }

  const handleClearFileSelected = () => setIsFileSelected(false);

  const createFile: CreateFileFn = async (response: Response, fileName: string) => {
    //const blob = await setBlob(response, fileName);
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    document.body.removeChild(link);
  }

  const fetchData: FetchDataFn = async (fetchProps: FetchProps) => {
    return await new FetchBuilder()
      .setPath(fetchProps.path!)
      .setHeaders(fetchProps.contentType!)
      .build();
  }

  const downloadCommissionFile: Download = (createFile, fetchData) =>
    async ( fetchProps)  => createFile(await fetchData(fetchProps), fetchProps.path!);

  React.useEffect(() => {
    (async () => {
      isFileSelected && await downloadCommissionFile(
        createFile,
        fetchData
      )(fetchDataProps);
      handleClearFileSelected();
    })();
  },[isFileSelected])

  return (
    <div style={{ height: "620px" }}>
      <DataGrid<Commission>
        rowData={rowData}
        defaultColDef={{sortable: true}}
        columnDefs={columnDefs}
        rowActions={rowActions}
        topPanel={topPanel}
        pagination
        paginationPageSize={50}
        getRowId={(person) => person.lastModified}
        onCellEditRequest={cellEditRequest}
        readOnlyEdit={true}
        onFirstDataRendered={p => p.api.sizeColumnsToFit()}
      />
    </div>
  )
}

export default FileDisplayed;