import React from 'react'
import {
  LinkGroupMany,
  LinkGroups,
  LinkGroupSingle,
  NavRoute, SideNavLinkGroupFn,
  TitleMessage,
  User,
} from '@platform/cj-platform-navigation'
import { MDIcon } from '@cjdev-internal/visual-stack-x/MDIcon'
import messages from './messages'


const createPlatformRoute = (path: string, titleMessage: TitleMessage, Content: React.ElementType): NavRoute => {

  const createLinkGroups: SideNavLinkGroupFn = (user: User) => {

    const homeGroup: LinkGroupSingle = {
      label: 'Home',
      HeaderIcon: () => (<MDIcon icon={'home'} size={18} />),
      isCurrentArea: path === '/',
      link: '/'
    }

    const commissionAdminToolGroup: LinkGroupMany = {
      label: 'Commission Admin Tool',
      HeaderIcon: () => (<MDIcon icon={'file-arrow-left-right'} size={18} />),
      isCurrentArea: path.includes('/commission-request') || path.includes('/commission-result'),
      links: [
        {
          label: 'Correction Request',
          path: '/commission-request',
          isCurrentPage: path.includes('/commission-request') || path === '/'
        },
        {
          label: 'Correction Results',
          path: '/commission-result',
          isCurrentPage: path.includes('/commission-result')
        }
      ]
    }

    const links = [homeGroup, commissionAdminToolGroup]

    return {
      areaLabel: messages.areaLabel.defaultMessage,
      groups: links
    }
  }

  return ({
    path,
    titleMessage,
    Content: () => {
      return (
        <Content />
      )
    },
    sideNavLinkGroups: createLinkGroups
  })
}
export default createPlatformRoute



