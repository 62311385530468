import React from 'react'
import { Stack } from '@cjdev-internal/visual-stack-x/Stack'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import { MDIcon, MDIconName } from '@cjdev-internal/visual-stack-x/MDIcon'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
import { Card } from '@cjdev-internal/visual-stack-x/Card'
import './index.css'
import { HomeCardProps } from '../../types'

const HomeCard = ({title, description, mdiIconName, Footer}: HomeCardProps) => {
  const FooterElement = () => Footer ? <Footer/> : <></>;

  return (
    <Card className="home-page-card" >
      <Stack rowGap={'larger'}>
        <Row columnGap={'medium'}>
          <MDIcon icon={mdiIconName} size={20} inline={true} />
          <Text weight="bold" size={17}>{title}</Text>
        </Row>
        <Text size={16}>{description}</Text>
        <FooterElement/>
      </Stack>
    </Card>
  )
}

export default HomeCard