import React from 'react';
import messages from '../../messages'
import PageWithPanel from '../../components/PageWithPanel'
import FileUploader from '../../components/Commission/FileUploader'
import FileSender from '../../components/Commission/FileSender'
import Notification from '../../components/Commission/Notification'
import CommissionToolWikiLink from '../../components/Commission/CommissionToolWikiLink'
import { useIntl } from '@platform/cj-platform-navigation'

const CommissionRequest = () => {
  const intl = useIntl();
  return (<PageWithPanel
    description={intl.formatMessage(messages.labelCommissionRequestDescription)}
    WikiLink={CommissionToolWikiLink}
    BodyContent={FileUploader}
    FooterContent={FileSender}
    PanelContent={Notification}
  />)
}

export default CommissionRequest;