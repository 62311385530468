import React from 'react'
import styles from './Loading.module.css'
import { LoadingAnimation } from '@cjdev-internal/visual-stack-x/LoadingAnimation'

const Logo = () => <img src={'https://platform.cj.com/nav/static/logo.svg'} alt="CJ logo" />
const Animation = () => <LoadingAnimation loadingMessage="Loading data.."/>
const Loading = ({animated}: {animated?: boolean}) => {
  const Child = () => animated ? <Animation/> : <Logo/>
  return (
    <div className={styles.loadingInContainer}>
      <Child/>
    </div>
  )
}
 export default Loading;