import { WikiLinkProps } from '../../types'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import React from 'react'

const WikiLink = ({ more, title, link }: WikiLinkProps): JSX.Element => {
  return (
    <Row gap="medium" align="center" paddingLeft="none" paddingTop="small" paddingBottom="small">
      {more}
      <a href={link} target="_blank" rel="noreferrer">
        <i>{title}</i>
      </a>
    </Row>
  )
}

export default WikiLink