import { defineMessages } from "react-intl";

export default defineMessages( {
  areaLabel: {
    id: "fintech.commission.tool.area-label",
    defaultMessage: "Fintech Tools",
  },
  labelFintechAdminTools: {
    id: "fintech.commission.tool.label-admin-tools",
    defaultMessage: "Fintech Admin Tools",
  },
  labelCommissionAdminTool: {
    id: "fintech.commission.tool.label-commission-admin-tool",
    defaultMessage: "Commission Admin Tool",
  },
  labelCommissionAdminToolDescription: {
    id: "fintech.commission.tool.label-commission-admin-tool-description",
    defaultMessage: "The Commission Admin Tool is an application intended to give super-users capabilities to modify the locking date of orders.",
  },
  labelSearchCommissionFile: {
    id: "fintech.commission.tool.label-content",
    defaultMessage: "Click the button below to browse the commission file.",
  },
  labelCommissionRequest: {
    id: "fintech.commission.tool.label-commission-request",
    defaultMessage: "Correction Request",
  },
  labelCommissionRequestDescription: {
    id: "fintech.commission.tool.label-commission-request-description",
    defaultMessage: "Upload the commission file to be processed",
  },
  labelCommissionResponse: {
    id: "fintech.commission.tool.label-commission-response",
    defaultMessage: "Correction Results",
  },
  labelCommissionResponseDescription: {
    id: "fintech.commission.tool.label-commission-response-description",
    defaultMessage: "Get and download the commission results",
  },
  labelHomeWelcome: {
    id: "fintech.commission.tool.label-home-welcome",
    defaultMessage: "welcome to Fintech Tools",
  },
  labelFileSelected: {
    id: "fintech.commission.tool.label-file-selected",
    defaultMessage: "Selected file: ",
  },
  labelFileSend: {
    id: "fintech.commission.tool.label-file-send",
    defaultMessage: "SEND",
  },
  labelTableColumnKey: {
    id: "fintech.commission.tool.label-table-column-key",
    defaultMessage: "Key",
  },
  labelTableColumnLastModified: {
    id: "fintech.commission.tool.label-table-column-lastModified",
    defaultMessage: "Last Modified",
  },
  labelTableColumnUser: {
    id: "fintech.commission.tool.label-table-column-user",
    defaultMessage: "User",
  },
  labelTableColumnEmail: {
    id: "fintech.commission.tool.label-table-column-email",
    defaultMessage: "Email",
  },
  labelMoreInformation: {
    id: "fintech.commission.tool.label-table-column-more-information",
    defaultMessage: "More information on:",
  },
  labelCommissionToolWiki: {
    id: "fintech.commission.tool.label-wiki",
    defaultMessage: "➥ Commission Tool Wiki",
  },
  labelCommissionFileUploadFileSize: {
    id: "fintech.commission.tool.label-file-upload-file-size",
    defaultMessage: "File size:",
  },
  labelCommissionFileUploadBytes: {
    id: "fintech.commission.tool.label-file-upload-bytes",
    defaultMessage: "bytes",
  }
})