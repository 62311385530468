import React from 'react';
import createPlatformRoute from './createPlatformRoute'
import AppProvider from './context/AppProvider'
import messages from './messages'
import translations from './translation'
import { PlatformApp, PlatformDomains } from '@platform/cj-platform-navigation'
import CommissionRequest from './containers/CommissionRequest'
import CommissionResult from './containers/CommissionResult'
import Home from './containers/Home'


const App = () => {
  const homeRoute = createPlatformRoute("/", messages.labelFintechAdminTools, Home);
  const requestRoute = createPlatformRoute("/commission-request", messages.labelCommissionRequest, CommissionRequest);
  const resultRoute = createPlatformRoute("/commission-result", messages.labelCommissionResponse, CommissionResult);
  const routes = [homeRoute, requestRoute, resultRoute];
  const platformDomains : PlatformDomains = { members: 'prod' };

  return <AppProvider>
      <PlatformApp routes={routes} translations={translations.translations} onIntlError={translations.onIntlError} platformDomains={platformDomains} />
  </AppProvider>
}

export default App;