import { FetchResponse, ToastOptions } from '../types'

export const parseFetchDataToToastOptions = (data: FetchResponse) => {
  const opt: ToastOptions = {
    message: data.message,
    type: data.status === 200 ? 'success' : 'warning',
    contentStyle: { textAlign: "center", alignContent: "center", alignItems: "center", alignSelf: "center", fontSize: "18px", minWidth: "500px", minHeight: "35px" },
    dismissible: true,
    duration: 4000
  };
  return opt;
}