import en from './en.json';
import {
  InvalidConfigError,
  MessageFormatError,
  MissingDataError,
  MissingTranslationError,
  UnsupportedFormatterError
} from 'react-intl';
import { FormatError } from 'intl-messageformat'

const onIntlError = (err: MissingTranslationError | MessageFormatError | MissingDataError | InvalidConfigError | UnsupportedFormatterError | FormatError ) => {
  // simplify any missing translation errors
  if (err.code === "MISSING_TRANSLATION") {
    console.warn(`[REACT INTL] Missing translation: ${err.descriptor?.id}`);
    return false;
  }
  // simplify any format errors
  if (err.code === "FORMAT_ERROR") {
    console.warn(`[REACT INTL] Format error: ${err.descriptor?.id}`);
    return false;
  }
};

const translations = { en };
export default { translations, onIntlError };
