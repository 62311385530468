import React, { useContext } from 'react'
import { useToast } from '@cjdev-internal/visual-stack-x/Toast'
import { AdminToolValues, FetchResponse, ToastOptions } from '../../../types'
import AppContext from '../../../context/AppContext'

const Notification = () => {
  const [mount, show, dismiss] = useToast(true);
  const { eventEmitter } = useContext<AdminToolValues>(AppContext);

  //Notification Callback
  const showNotification = (toastOptions: ToastOptions) => {
    show(toastOptions);
  }

  //Register event
  React.useEffect(() => {
    eventEmitter.on('SHOW_TOAST', showNotification);
    return () => {
      eventEmitter.removeListener('SHOW_TOAST', showNotification);
    };
  }, []);

  return (
    <>
      {mount}
    </>
  )
}


export default Notification