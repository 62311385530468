import 'regenerator-runtime/runtime'
import React, { useState } from 'react'
import AppContext from './AppContext'
import {
  AdminToolValues,
  AppProviderProps,
  CommissionValues,
  FetchResponse,
  ToastOptions
} from '../types'
import Commission from '../components/Commission/Commission'
import { EventEmitter } from 'events'

const AppProvider = ({ children }: AppProviderProps): JSX.Element => {
  const [commissionFile, setCommissionFile] = useState<File | null>(null)
  const [commissionResponse, setCommissionResponse] = useState<Commission[] | undefined>(undefined)
  const [isUnauthorized, setIsUnauthorized] = useState<boolean>(false)
  const eventEmitter = new EventEmitter();

  const handleClearInputFile = () => {
    console.log('Clearing commission file')
    setCommissionFile(null)
  }

  const handleFileUpload = (file: File) => {
    console.log('File Charged')
    setCommissionFile(file)
  }

  const handleSetToastOptions = (data: FetchResponse) => {
    const opt: ToastOptions = {
      message: data.message,
      type: data.status === 200 ? 'success' : 'warning',
      contentStyle: { textAlign: "center", alignContent: "center", alignItems: "center", alignSelf: "center", fontSize: "18px", minWidth: "500px", minHeight: "35px" },
      dismissible: true,
      duration: 4000
    }
    console.log('emitting SHOW_TOAST');
    eventEmitter.emit('SHOW_TOAST', opt);
  }

  const handleAuthorization = () => setIsUnauthorized(!isUnauthorized)

  const handleCommissionResponse = (data: Commission[]) => setCommissionResponse(data)

  const commissionValues: CommissionValues = {
    commissionFile, // fileUploader, fileSender
    handleClearInputFile, // fileUploader
    handleFileUpload, // fileUploader
    handleSetToastOptions, // notification
    commissionResponse, // dataGridResponse
    handleCommissionResponse // dataGridResponse
  }

  const value: AdminToolValues = {
    isUnauthorized,
    handleAuthorization,
    commissionValues,
    eventEmitter
  }

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )

}

export default AppProvider