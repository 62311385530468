import { useIntl } from '@platform/cj-platform-navigation'
import WikiLink from '../../WikiLink'
import messages from '../../../messages'
import React from 'react'

const CommissionToolWikiLink = () => {
  const intl = useIntl();
  return (<WikiLink
    more={intl.formatMessage(messages.labelMoreInformation)}
    title={intl.formatMessage(messages.labelCommissionToolWiki)}
    link="https://wiki.cnvrmedia.net/pages/viewpage.action?spaceKey=CJE&title=Commission+Admin+Tool" />)
}

export default CommissionToolWikiLink