import React, { useContext } from 'react'
import { PageContent } from '@cjdev-internal/visual-stack-x/components/PageContent'
import { Body, Footer, Header, Panel } from '@cjdev-internal/visual-stack-x/Panel'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
import { AdminToolValues, PageWithPanelProps } from '../../types'
import Unauthorized from '../403'
import AppContext from '../../context/AppContext'
import './index.css';
import { Grid } from '@cjdev-internal/visual-stack-x/Grid'

const PageWithPanel = ({description, WikiLink, BodyContent, FooterContent, PanelContent}: PageWithPanelProps) => {
  const { isUnauthorized }  = useContext<AdminToolValues>(AppContext);
  const FooterContentValid = () => FooterContent ? <FooterContent/> : <></>;
  const PanelContentValid = () => PanelContent ? <PanelContent/> : <></>;
  const WikiLinkValid = () => WikiLink ? <WikiLink/> : <></>;

  return isUnauthorized ? <Unauthorized/> : (
    <PageContent>
      <div className="commission-page">
        <div className="commission-page-content">
          <Panel>
            <Header>
              <Grid gap="large" columns="1fr auto">
                <Text>{description}</Text>
                <WikiLinkValid/>
              </Grid>
            </Header>
            <Body>
              <BodyContent/>
            </Body>
            <Footer>
              <FooterContentValid/>
            </Footer>
            <PanelContentValid/>
          </Panel>
        </div>
      </div>
    </PageContent>
  )
}

export default PageWithPanel;