import React, { useContext } from 'react'
import messages from '../../messages'
import { useIntl, useUser } from '@platform/cj-platform-navigation'
import { AdminToolValues } from '../../types'
import AppContext from '../../context/AppContext'
import Unauthorized from '../../components/403'
import { PageContent } from '@cjdev-internal/visual-stack-x/components/PageContent'
import { Body, Header, Panel } from '@cjdev-internal/visual-stack-x/Panel'
import { Grid } from '@cjdev-internal/visual-stack-x/Grid'
import HomeCard from '../../components/HomeCard'
import CommissionToolWikiLink from '../../components/Commission/CommissionToolWikiLink'

const CommissionResult = () => {
  const { isUnauthorized } = useContext<AdminToolValues>(AppContext)
  const intl = useIntl()
  const user = useUser()
  const welcomeMessage = `${user.lastName} ${user.firstName} ${intl.formatMessage(messages.labelHomeWelcome)}`

  return isUnauthorized ? <Unauthorized /> : (
    <PageContent>
      <div className="commission-page">
        <div className="commission-page-content">
          <Panel>
            <Header>
              <h2>{welcomeMessage}</h2>
            </Header>
            <Body>
              <Grid columns="1fr 1fr" gap="large">
                <HomeCard
                  title={intl.formatMessage(messages.labelCommissionAdminTool)}
                  description={intl.formatMessage(messages.labelCommissionAdminToolDescription)}
                  mdiIconName={'file-arrow-left-right'}
                  Footer={CommissionToolWikiLink} />
                {/*<HomeCard
                  title={'Payout Tools'}
                  description={'Coming soon...'}
                  mdiIconName={'chart-scatter-plot-hexbin'} />*/}
              </Grid>
            </Body>
          </Panel>
        </div>
      </div>
    </PageContent>
  )
}

export default CommissionResult