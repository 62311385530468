import React from 'react';
import PageWithPanel from '../../components/PageWithPanel'
import messages from '../../messages'
import FileFetcher from '../../components/Commission/FileFetcher'
import CommissionToolWikiLink from '../../components/Commission/CommissionToolWikiLink'
import Notification from '../../components/Commission/Notification'
import { useIntl } from '@platform/cj-platform-navigation'

const CommissionResult = () => {
  const intl = useIntl();
  return (<PageWithPanel
    description={intl.formatMessage(messages.labelCommissionResponseDescription)}
    WikiLink={CommissionToolWikiLink}
    BodyContent={FileFetcher}
    PanelContent={Notification}
  />)
}

export default CommissionResult;