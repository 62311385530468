import { FetchResponse } from '../types'
import cjAuth from '../auth/cjAuth';

export const fetcher = async (path: string, init: RequestInit): Promise<FetchResponse> => {
  const url = `${process.env.REACT_APP_ADMIN_TOOL_API_URL}${path}`;
  const requestInit = await setAuthorization(init);
  const response = await fetch(url, requestInit);
  if (!response.ok){
    if (response.status === 403){
      //await cjAuth.logout();
      return {
        status: response.status,
        message: "Unauthorized.",
        error: true
      }
    }
  }
  if (response && response.ok && response.body) {
    const result = await response;
    return {
      status: result.status,
      message: "File was sent successfully.",
      error: false
    }
  }
  return {
    status: response.status,
    message: "Something went wrong.",
    error: true
  }
}

export const setAuthorization = async (request: RequestInit): Promise<RequestInit> => {
  const token = await cjAuth.getBearerToken();
  return { ...request, headers: { ...request.headers, 'Authorization': `Bearer ${token}`}};
}

export const setRequestInit = (body: any, method?: string, contentType?: string) => {
  const requestInit: RequestInit = {
    method: method ? method : "PUT",
    cache: "no-cache",
    headers: {
      'Content-Type': contentType ? contentType : "text/plain"
    },
    body
  }
  return requestInit;
}