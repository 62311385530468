import React, { useContext } from 'react'
import { Button } from '@cjdev-internal/visual-stack-x/Button'
import { setRequestInit, fetcher } from '../../../fetch'
import { AdminToolValues } from '../../../types'
import AppContext from '../../../context/AppContext'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import { parseFetchDataToToastOptions } from '../../../tools/utils'

const FileSender = () => {
  const {
    handleAuthorization,
    commissionValues: { commissionFile },
    eventEmitter
  } = useContext<AdminToolValues>(AppContext)

  const handleClick = async () => {
    if (commissionFile) {
      const body = await commissionFile.text()
      const init = setRequestInit(body)
      const data = await fetcher(`/${commissionFile.name}`, init)
      if (data.status === 403) {
        handleAuthorization()
      }
      const options = parseFetchDataToToastOptions(data)
      eventEmitter.emit('SHOW_TOAST', options)
      eventEmitter.emit('CLEAR_FILE_UPLOAD')
    }
  }

  return (
    <Row gap="small">
      <Button type="primary" disabled={!commissionFile} onClick={handleClick}>Send</Button>
    </Row>
  )
}

export default FileSender
